import React from "react"
import Container from "react-bootstrap/Container"
import { useTranslation } from "react-i18next"
import { Link as GatsbyLink } from "gatsby"

import { itunesLink, googlePlayLink } from "../../constants/links"

import itunesBadge from "../../images/itunes-badge.svg"
import androidBadge from "../../images/android-badge.svg"

import styles from "./footer.module.css"

export default function Footer() {
  const { t } = useTranslation()

  const FooterItems = t("footer.items")
  const RenderFooterItems = []

  for (const item of FooterItems) {
    let linkRender

    if (item.url.includes("https://")) {
      linkRender = (
        <a href={item.url} target="_blank" rel="noreferrer">
          {item.text}
        </a>
      )
    } else {
      linkRender = <GatsbyLink to={item.url}>{item.text}</GatsbyLink>
    }
    RenderFooterItems.push(<li>{linkRender}</li>)
  }

  const footerText = t("footer.text")

  return (
    <footer className={styles.footer}>
      <Container>
        <div className="row">
          <div className="col-md-6">
            <p className={styles.footerText}>{footerText}</p>
            <ul className={`list-inline ${styles.footerNav}`}>
              {RenderFooterItems}
            </ul>
          </div>
          <div className="col-md-6">
            <div className={styles.footerDownload}>
              <ul className="list-inline">
                <li>
                  <a
                    href={itunesLink}
                    className={styles.footerDownloadButton}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={`${t("image.alt")} Iphone app on Itunes`}
                      src={itunesBadge}
                    />
                  </a>
                </li>
                <li>
                  <a
                    href={googlePlayLink}
                    className={styles.footerDownloadButton}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt={`${t("image.alt")} Android app on Google Play`}
                      src={androidBadge}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}
